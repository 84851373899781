import React from "react";
import "./PricingComponent.css";
import { Link } from "react-router-dom";

const PricingComponent = () => {
  return (
    <section className="troo-da-price-section" id="troo-da-price-section">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="troo-tile">
              <span>Price we offer</span>
              <h2>Our pricing affordable plans</h2>
            </div>
          </div>
          <div className="col-md-5">
            <div className="pricing-text">
              <p>
                It is a long established fact that it is also reader we arewill
                be distracted by the readable.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="pricing-col">
              <div className="pricing-ss">
                <div className="pricing-title">
                  <h4>Traditional package</h4>
                  <div className="review-star">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="pricing-icon">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 29.5312L12.0312 31.7188V27.3438L14.2188 25.1562V20.7812L3.28125 22.9688V18.5938L14.2188 13.125L14.2341 6.5625C14.2341 5.69226 14.5798 4.85766 15.1952 4.24231C15.8105 3.62695 16.6451 3.28125 17.5154 3.28125C18.3856 3.28125 19.2202 3.62695 19.8356 4.24231C20.4509 4.85766 20.7966 5.69226 20.7966 6.5625L20.812 13.125L31.7495 18.5938V22.9688L20.812 20.7812V25.1562L22.9688 27.3438V31.7188L17.5 29.5312Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="pricing-list">
                <ul>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>which is a long established.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>It is a long established fact.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Traditional package we Ipsum </span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>There are many that of</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>passages of Lorem a availablebut.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Randomised words which don't look</span>
                  </li>
                </ul>
              </div>
              <div className="price-rate">
                <span>$</span>
                <div className="rate-text">29.00</div>
                <div className="pakage-pric">/ Monthy packege</div>
              </div>
              <div className="make-btn">
                {" "}
                <Link href="#" className="btn btn-lg">
                  {" "}
                  <span>Select This Plan</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-col">
              <div className="pricing-ss">
                <div className="pricing-title">
                  <h4>Luxury package</h4>
                  <div className="review-star">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="pricing-icon">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.6479 10.9926L24.0471 15.3126L28.4221 27.3439L25.1409 30.6251L18.6046 21.0576L15.2971 24.0626V27.3439L12.0159 30.6251L10.0755 24.9029L4.35962 22.9689L7.64087 19.6876H10.9221L14.2034 16.4064L4.35962 9.84386L7.64087 6.56261L19.6721 10.9376L24.0075 6.35218L23.9144 6.42991C24.219 6.12467 24.5807 5.88246 24.979 5.71711C25.3772 5.55177 25.8042 5.46655 26.2354 5.46631C26.6666 5.46607 27.0936 5.55083 27.492 5.71573C27.8904 5.88064 28.2525 6.12246 28.5574 6.42737C28.8623 6.73227 29.1041 7.09429 29.269 7.49271C29.4339 7.89114 29.5187 8.31816 29.5184 8.74936C29.5182 9.18057 29.433 9.60749 29.2676 10.0057C29.1023 10.404 28.8601 10.7657 28.5548 11.0703L28.6479 10.9926Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="pricing-list">
                <ul>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>which is a long established.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>It is a long established fact.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Traditional package we Ipsum </span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>There are many that of</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>passages of Lorem a availablebut.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Randomised words which don't look</span>
                  </li>
                </ul>
              </div>
              <div className="price-rate">
                <span>$</span>
                <div className="rate-text">49.00</div>
                <div className="pakage-pric">/ Monthy packege</div>
              </div>
              <div className="make-btn">
                <Link href="#" className="btn btn-lg">
                  <span>Select This Plan</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-col">
              <div className="pricing-ss">
                <div className="pricing-title">
                  <h4>Parsonalized package</h4>
                  <div className="review-star">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="pricing-icon">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.7808 14.4062L17.5 23.687L11.3129 17.4998L20.5936 8.21901C24.1079 4.70477 27.6221 4.73905 29.1208 4.96266C29.3516 4.99706 29.5652 5.10467 29.7302 5.26965C29.8952 5.43462 30.0028 5.64823 30.0372 5.87899C30.2608 7.37772 30.2951 10.892 26.7808 14.4062Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M25.234 15.9531V24.7809C25.234 24.9245 25.2057 25.0667 25.1507 25.1994C25.0958 25.3321 25.0152 25.4527 24.9136 25.5543L20.4919 29.976C20.3519 30.116 20.1766 30.2153 19.9845 30.2635C19.7925 30.3116 19.591 30.3068 19.4016 30.2494C19.2121 30.192 19.0417 30.0843 18.9087 29.9377C18.7756 29.7912 18.6848 29.6112 18.646 29.4171L17.5 23.6871"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.0468 9.76611H10.2191C10.0755 9.76611 9.93323 9.7944 9.80053 9.84937C9.66783 9.90434 9.54725 9.9849 9.44569 10.0865L5.02397 14.5082C4.88398 14.6482 4.78461 14.8235 4.73646 15.0156C4.68831 15.2076 4.69318 15.4091 4.75054 15.5985C4.80791 15.788 4.91563 15.9584 5.06221 16.0914C5.20879 16.2245 5.38874 16.3153 5.58286 16.3541L11.3128 17.5001"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.3813 26.3021C10.3226 29.8743 5.12561 29.8743 5.12561 29.8743C5.12561 29.8743 5.12561 24.6774 8.69783 22.6187"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="pricing-list">
                <ul>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>which is a long established.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>It is a long established fact.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Traditional package we Ipsum </span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>There are many that of</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>passages of Lorem a availablebut.</span>
                  </li>
                  <li>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49999 9.99984L9.16666 11.6665L12.9167 7.9165M6.11146 3.18208C6.78132 3.12863 7.41725 2.86522 7.92871 2.42935C9.12226 1.41222 10.8777 1.41222 12.0713 2.42935C12.5827 2.86522 13.2187 3.12863 13.8885 3.18208C15.4517 3.30683 16.693 4.54812 16.8177 6.11131C16.8712 6.78117 17.1346 7.4171 17.5705 7.92856C18.5876 9.12211 18.5876 10.8776 17.5705 12.0711C17.1346 12.5826 16.8712 13.2185 16.8177 13.8884C16.693 15.4516 15.4517 16.6928 13.8885 16.8176C13.2187 16.871 12.5827 17.1345 12.0713 17.5703C10.8777 18.5875 9.12226 18.5875 7.92871 17.5703C7.41725 17.1345 6.78132 16.871 6.11146 16.8176C4.54828 16.6928 3.30698 15.4516 3.18224 13.8884C3.12878 13.2185 2.86537 12.5826 2.42951 12.0711C1.41237 10.8776 1.41237 9.12211 2.42951 7.92856C2.86537 7.4171 3.12878 6.78117 3.18224 6.11131C3.30698 4.54812 4.54828 3.30683 6.11146 3.18208Z"
                        stroke="#232323"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Randomised words which don't look</span>
                  </li>
                </ul>
              </div>
              <div className="price-rate">
                <span>$</span>
                <div className="rate-text">99.00</div>
                <div className="pakage-pric">/ Monthy packege</div>
              </div>
              <div className="make-btn">
                <Link href="#" className="btn btn-lg">
                  <span>Select This Plan</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="view-all-pric">
              <div className="make-btn">
                <Link to="/Home/Pricing_Table" className="btn btn-lg">
                  <span>View All Pricing Cards</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingComponent;
